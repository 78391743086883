<template>
  <div>
    <section class="news">
      <div class="news-center">
        <h3 class="title">
          <span>新闻中心</span>
        </h3>
        <p class="title-line">
          <span class="center-line"></span>
        </p>
        <div class="news-content">
          <el-row style="margin-bottom: 30px">
            <el-col :span="8">
              <div class="item" @click="itemEvent('Housewarming')">
                <div class="item_imgs">
                  <img
                    src="../../assets/image/enterpriseNews/housemoving.png"
                    alt=""
                  />
                </div>
                <div class="textbox">
                  <p>有为信通乔迁之喜</p>
                  <p>27 05/2021</p>
                  <p></p>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="item" @click="itemEvent('tangshan')">
                <div class="item_imgs">
                  <img
                    src="../../assets/image/enterpriseNews/tangshan/img2.jpg"
                    alt=""
                  />
                  
                </div>
                <div class="textbox">
                  <p>祝贺祥业科技集团与唐山市政府</p>
                  <p>01/2023</p>
                  <div style="text-align: left;margin-top: -21px;">签订合作协议！</div>
                  <!-- <p></p> -->
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="itemnoimg" @click="itemEvent('list')">
                <div>
                  <p>新闻类</p>
                  <p>News class</p>
                  <p><span></span></p>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div
                class="itemnoimg itemnoimgleft"
                @click="itemEvent('list', 1)"
              >
                <div>
                  <p>活动类</p>
                  <p>Activity class</p>
                  <p><span></span></p>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="item" @click="itemEvent('lecture')">
                <div class="item_imgs">
                  <img
                    src="../../assets/image/enterpriseNews/img3.png"
                    alt=""
                  />
                </div>

                <div class="textbox">
                  <p>华为北京机器视觉好望学堂第三期</p>
                  <p>06/2021</p>
                  <p></p>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="item" @click="itemEvent('Imageexhibition')">
                <div class="item_imgs">
                  <img
                    src="../../assets/image/enterpriseNews/img4.png"
                    alt=""
                  />
                </div>

                <div class="textbox">
                  <p>有为信通护航“云游非遗·影像展···</p>
                  <p>06/2021</p>
                  <p></p>
                </div>
              </div>
            </el-col>
          </el-row>
          <p class="morebtn">
            <img src="../../assets/image/enterpriseNews/morebtn.png" alt="" />
          </p>
        </div>
      </div>
    </section>
    <section class="news elegantdemeanor">
      <div class="news-center">
        <h3 class="title">
          <span>员工风采</span>
        </h3>
        <p class="title-line">
          <span class="center-line"></span>
        </p>
        <div class="news-content">
          <el-row style="margin-bottom: 78px">
            <el-col :span="8">
              <div class="item" @click="itemEvent('Housewarming')">
                <div class="itemed_imgs">
                  <img
                    src="../../assets/image/enterpriseNews/img5.png"
                    alt=""
                  />
                </div>
                <div class="textbox">
                  <p>有为信通乔迁之喜</p>
                  <p>27 05/2021</p>
                  <p></p>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="item" @click="itemEvent('Housewarming')">
                <div class="itemed_imgs">
                  <img
                    src="../../assets/image/enterpriseNews/img6.png"
                    alt=""
                  />
                </div>
                <div class="textbox">
                  <p>有为信通乔迁之喜</p>
                  <p>27 05/2021</p>
                  <p></p>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="item" @click="itemEvent('Housewarming')">
                <div class="itemed_imgs">
                  <img
                    src="../../assets/image/enterpriseNews/img7.png"
                    alt=""
                  />
                </div>
                <div class="textbox">
                  <p>华为智能协作体验中心揭幕仪式</p>
                  <p>05/2021</p>
                  <p></p>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="wonderfulmoment">
            <span> 精彩时刻 </span>
            <span> Welfare </span>
            <span>
              <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
            </span>
          </div>
          <div class="food">
            <div class="mn-wrap" @mouseover="enter" @mouseleave="leave">
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide
                  ><img
                    src="../../assets/image/enterpriseNews/food-img1.png"
                    alt=""
                    style="width: 342px; height: 248px"
                /></swiper-slide>
                <swiper-slide
                  ><img
                    src="../../assets/image/enterpriseNews/food-img2.png"
                    alt=""
                    style="width: 342px; height: 248px"
                /></swiper-slide>
                <swiper-slide
                  ><img
                    src="../../assets/image/enterpriseNews/food-img3.png"
                    alt=""
                    style="width: 342px; height: 248px"
                /></swiper-slide>
                <swiper-slide
                  ><img
                    src="../../assets/image/enterpriseNews/food-img4.png"
                    alt=""
                    style="width: 342px; height: 248px"
                /></swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 10,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 2000,
        slidesPerView: 4,
        allowTouchMove: false,
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  methods: {
    itemEvent(r, n) {
      if (!n) {
        this.$router.push("/" + r);
      } else {
        this.$router.push({ name: "list", params: { type: n } });
      }
    },
    enter() {
      this.$refs.mySwiper.swiper.autoplay.stop();
    },
    leave() {
      this.$refs.mySwiper.swiper.autoplay.start();
    },
  },
};
</script>
<style lang="scss">
// .mn-wrap {
//     width: 1300px;
//     height: 248px;
//     // background: #FFFFFF;
//     overflow: hidden;
//     margin: 50px;
//     position: relative;
// }
.swiper-container {
  width: 1466px;
  height: 100%;
}
.swiper-wrapper {
  -webkit-transition-timing-function: linear; /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}
// .swiper-container .swiper-slide{
//     // width: 100%;
//     // height: 100%;
//     // display: block;
//     // overflow: hidden;
// }
.swiper-container .swiper-slide {
  width: 350px !important;
  height: 248px !important;
  margin-top: 40px;
  margin-left: 26px;
}

// .mn-swiper .swiper-wrapper{
//     width: 100%;
//     margin: 0 auto;
// }
</style>
<style lang="scss" scoped>
.news {
  height: 1080px;
  background-image: url("../../assets/image/enterpriseNews/newsbg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .news-center {
    padding-top: 78px;
    font-size: 20px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .title {
      font-size: 40px;
      color: #17171c;
      // margin-bottom: 17px;
    }
    .title-line {
      margin-bottom: 36px;
      .center-line {
        width: 70px;
        height: 4px;
        background: #3c4047;
        border-radius: 2px;
        display: inline-block;
      }
    }
    .news-content {
      .item {
        cursor: pointer;
        max-width: 468px;
        background-color: #f5f5f5;
        .item_imgs {
          overflow: hidden;
          img {
            width: 468px;
            height: 249px;
          }
        }
        .textbox {
          padding: 15px 20px 14px 16px;
          p:nth-child(1) {
            text-align: left;
            height: 25px;
          }
          p:nth-child(2) {
            font-size: 30px;
            color: #999;
            margin-top: -8px;
            text-align: right;
            height: 30px;
          }
          p:nth-child(3) {
            width: 66px;
            height: 1px;
            background: #3c4047;
          }
        }
      }
      .itemnoimg {
        max-width: 468px;
        background-color: #f5f5f5;
        div {
          p {
            text-align: right;
            cursor: pointer;
          }
          padding: 71px 64px 60px 0;
          p:nth-child(1) {
            color: #333;
            line-height: 56px;
            height: 56px;
            font-size: 56px;

            margin-bottom: 28px;
          }
          p:nth-child(2) {
            color: #999;
            height: 42px;
            font-size: 42px;
            margin-bottom: 40px;
          }
          p:nth-child(3) {
            span {
              background-image: url("../../assets/image/enterpriseNews/More.png");
              width: 68px;
              height: 24px;
              display: inline-block;
            }
            span:hover {
              background-image: url("../../assets/image/enterpriseNews/activeMore.png");
            }
          }
        }
      }
      .itemnoimgleft {
        div {
          padding: 71px 0 60px 62px;
          p {
            text-align: left !important;
          }
        }
      }
      .morebtn {
        margin: 84px 0 0 0;
        img {
          cursor: pointer;
        }
      }
    }
  }
}
.elegantdemeanor {
  background-image: url("../../assets/image/enterpriseNews/Elegantdemeanor.png");
  .item {
    max-width: 488px !important;
    .itemed_imgs {
      overflow: hidden;
      img {
        width: 488px;
        height: 320px;
      }
    }
  }
  .wonderfulmoment {
    display: flex;
    justify-content: space-between;
    line-height: 32px;
    color: #121417;
    text-align: left;
    margin-bottom: 48px;
    span {
      font-size: 32px;
      display: inline-block;
    }
    span:nth-child(2) {
      margin: 0 10px 0 18px;
    }
    span:nth-child(3) {
      img {
        width: 100%;
      }
      max-width: 1190px;
    }
  }
  .food {
    //     display: flex;
    // justify-content: space-between;
  }
}

// hover事件

.item img:hover {
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
</style>